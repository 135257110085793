// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-tsx": () => import("./../../../src/pages/admin.tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-course-tsx": () => import("./../../../src/pages/course.tsx" /* webpackChunkName: "component---src-pages-course-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-page-2-tsx": () => import("./../../../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-protected-test-tsx": () => import("./../../../src/pages/protected/Test.tsx" /* webpackChunkName: "component---src-pages-protected-test-tsx" */),
  "component---src-pages-sign-change-password-tsx": () => import("./../../../src/pages/sign-change-password.tsx" /* webpackChunkName: "component---src-pages-sign-change-password-tsx" */),
  "component---src-pages-sign-in-tsx": () => import("./../../../src/pages/sign-in.tsx" /* webpackChunkName: "component---src-pages-sign-in-tsx" */),
  "component---src-pages-sign-lost-password-tsx": () => import("./../../../src/pages/sign-lost-password.tsx" /* webpackChunkName: "component---src-pages-sign-lost-password-tsx" */),
  "component---src-pages-sign-up-tsx": () => import("./../../../src/pages/sign-up.tsx" /* webpackChunkName: "component---src-pages-sign-up-tsx" */),
  "component---src-pages-stripe-cancel-tsx": () => import("./../../../src/pages/stripe-cancel.tsx" /* webpackChunkName: "component---src-pages-stripe-cancel-tsx" */),
  "component---src-pages-stripe-checkout-tsx": () => import("./../../../src/pages/stripe-checkout.tsx" /* webpackChunkName: "component---src-pages-stripe-checkout-tsx" */),
  "component---src-pages-stripe-success-tsx": () => import("./../../../src/pages/stripe-success.tsx" /* webpackChunkName: "component---src-pages-stripe-success-tsx" */),
  "component---src-pages-with-root-tsx": () => import("./../../../src/pages/withRoot.tsx" /* webpackChunkName: "component---src-pages-with-root-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-video-tsx": () => import("./../../../src/templates/video.tsx" /* webpackChunkName: "component---src-templates-video-tsx" */)
}

